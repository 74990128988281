<template>
    <!-- 销售合同月报 -->
    <div class="salesContractMonthBox">
        <div class="month-data-analysis" ref="inBoxHeight">
            <div class="top-echarts-two">
                <div class="echarts-left echarts-total">
                    <div class="title-box">
                        <h1>合同个数</h1>
                        <!-- <span @click="lookMore(3)">查看完整数据</span> -->
                    </div>
                    <div style="width 100%;height:2.7rem;flex 4" id="contractQuantityChild"></div>
                </div>
                <div class="echarts-right echarts-total">
                    <div class="title-box">
                        <h1>预计方量</h1>
                        <!-- <span @click="lookMore(4)">查看完整数据</span> -->
                    </div>
                    <div style="width 100%;height:2.7rem;flex 4" id="estimatedSalesChild"></div>
                </div>
            </div>
            <exportDom :dom-id="'.el-table__fixed-right'" :excel-name="'按合同类型月报'"></exportDom>
            <div class="table-list" style="margin-top:.1rem;">
                <el-table
                    id="salesTable"
                    :data="tabListDataName"
                    style="width: 100%"
                    max-height="400"
                    show-summary
                    :summary-method="getSummariesOne"
                >
                    <el-table-column
                        prop="name"
                        label="合同类型"
                        fixed
                        width="150"
                    >
                    </el-table-column>
                    <el-table-column
                        v-for="(date, index) in tabListDataDate"
                        :key="index"
                        :label="date"
                    >
                        <el-table-column
                            label="合同数量(个)"
                            :prop="date"
                            min-width="150"
                        >
                            <template slot-scope="scope">
                                <span v-for="(key, idx) in scope.row.list" :key="idx">
                                    <template v-if="key.date === date">
                                        {{ key.htsl }}
                                    </template>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="预计销售方量(方)"
                            :prop="date"
                            min-width="150"
                        >
                            <template slot-scope="scope">
                                <span v-for="(key, idx) in scope.row.list" :key="idx">
                                    <template v-if="key.date === date">
                                        {{ key.yjxsfl }}
                                    </template>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column
                        prop="htTotal"
                        label="数量小计(个)"
                        fixed="right"
                        width="120"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="total"
                        label="方量小计(方)"
                        fixed="right"
                        width="120"
                    >
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <alertEcharts v-if="showContract" :alert-status="alertStatus" @closeChildAlert="closeChildFn"></alertEcharts>
    </div>
</template>

<script>
// import addBgImages from '../../utils';
import exportDom from './../export';
import alertEcharts from './components/index';
export default {
    data() {
        return {
            tabListData: [],
            tabListDataName: [],
            tabListDataList: [],
            tabListDataDate: [],
            tabListEchartsHt: [],
            echartsColor: ['#9759D6', '#3E79C4', '#E6E429', '#13BD63'],

            showContract: false,
        };
    },
    components: {
        alertEcharts,
        exportDom,
    },
    mounted() {
        this.processingCenter();
    },
    methods: {
        // 合计方法
        getSummariesOne(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                // 获取与当前行时间相同的数据
                const arr = this.tabListDataList.filter(item => {
                    return column.property === item.date;
                });
                // 对应时间合同数量(个)和预计销售方量(方)的数值数组 // 对应每一列的数据数组
                let values = arr.map(item => {
                    if (column.label === '合同数量(个)') {return item.htsl;}
                    if (column.label === '预计销售方量(方)') {return item.yjxsfl;}
                });
                if (column.label === '合计(方)') {
                    values = data.map(item => {
                        if (column.label === '合计(方)') {
                            return item.total;
                        }
                    });
                }
                if (column.label === '合计合同数量(个)') {
                    values = data.map(item => {
                        if (column.label === '合计合同数量(个)') {
                            return item.htTotal;
                        }
                    });
                }
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return Math.round(prev + curr * 100);
                        }
                        return prev;
                    }, 0);
                    sums[index] = sums[index] / 100;
                } else {
                    sums[index] = ' ';
                }
            });
            return sums;
        },
        // 关闭子组件弹窗
        closeChildFn() {
            this.showContract = false;
        },
        // 查看详情
        lookMore(status) {
            // status 3:合同个数  4:预计方量  5:业务员销售合同数量统计   6:业务员销售预计方量统计
            this.alertStatus = status;
            this.showContract = true;
        },
        // 合同个数
        contractQuantityCh(data) {
            const myChart = this.$echarts.init(document.getElementById('contractQuantityChild'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                legend: {
                    data: this.tabListEchartsHt,
                    itemWidth: 10,
                    itemHeight: 10,
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.tabListDataDate,
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                            interval: 0,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(个)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                            padding: [10, 0, 0, 0],
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                fontSize: 10,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: '单位(个)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                    },
                ],
                series: data,
            };
            myChart.setOption(option, true);
        },
        // 预计销售方量
        estimatedSalesCh(data) {
            const myChart = this.$echarts.init(document.getElementById('estimatedSalesChild'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                legend: {
                    data: this.tabListEchartsHt,
                    itemWidth: 10,
                    itemHeight: 10,
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.tabListDataDate,
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                            interval: 0,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                            padding: [10, 0, 0, 0],
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                fontSize: 10,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                    },
                ],
                series: data,
            };
            myChart.setOption(option, true);
        },
        // 处理中心
        processingCenter() {
            this.$axios
                .get('/interfaceApi/report/tjfx/xsht/months/type?date=&datenum=12')
                .then(res => {
                    this.tabListData = res;
                    this.tabListDataList = res.list;
                    this.tabListDataDate = res.date;
                    // 表格渲染
                    this.tabListDataName = res.name.map(item => {
                        let yjxsflTotal = 0;
                        let htslTotal = 0;
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        arr.forEach(item => {
                            yjxsflTotal += Math.round(item.yjxsfl * 100) / 100;
                            htslTotal += Math.round(item.htsl * 100) / 100;
                        });
                        return {
                            name: item,
                            list: arr,
                            total: yjxsflTotal,
                            htTotal: htslTotal,
                        };
                    });
                    // 处理图表数据
                    this.tabListEchartsHt = res.name;
                    this.tabListEchartsHt.push('合计');
                    // 分组名称
                    const getSeriesData = res.name.map(item => {
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        return arr;
                    });
                    // 合同数量
                    let htEchartSeriesData = [];
                    htEchartSeriesData = getSeriesData.map((item, index) => {
                        let htItemListData = [];
                        htItemListData = item.map(info => {
                            return info.htsl ? info.htsl : ',';
                        });
                        const arrName = this.tabListEchartsHt[index];
                        const arrColor = this.echartsColor[index];
                        const htEchartSeries = {
                            name: arrName,
                            type: 'bar',
                            data: htItemListData,
                            label: {
                                fontFamily: 'QuartzLTStd',
                                show: true,
                                position: 'top',
                                color: arrColor,
                                fontSize: '14',
                                padding: 5,
                            },
                            itemStyle: {
                                color: arrColor,
                            },
                        };
                        return htEchartSeries;
                    });
                    // 预计销售方量
                    let flEchartSeriesData = [];
                    flEchartSeriesData = getSeriesData.map((item, index) => {
                        let flItemListData = [];
                        flItemListData = item.map(info => {
                            return info.yjxsfl ? info.yjxsfl : ',';
                        });
                        const arrName = this.tabListEchartsHt[index];
                        const arrColor = this.echartsColor[index];
                        const flEchartSeries = {
                            name: arrName,
                            type: 'bar',
                            data: flItemListData,
                            label: {
                                fontFamily: 'QuartzLTStd',
                                show: true,
                                position: 'right',
                                color: arrColor,
                                fontSize: '14',
                                padding: 5,
                            },
                            itemStyle: {
                                color: arrColor,
                            },
                        };
                        return flEchartSeries;
                    });
                    // 合同数量合计
                    const htTotalData = res.date.map(item => {
                        const arr = res.list.filter(key => {
                            return key.date === item;
                        });
                        let total = 0;
                        arr.forEach(a => {
                            total = Math.round(total * 100 + a.htsl * 100) / 100;
                        });
                        return total;
                    });
                    const htTotal = {
                        name: '合计',
                        type: 'line',
                        yAxisIndex: 1,
                        data: htTotalData,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            fontSize: '14',
                            padding: 5,
                        },
                        itemStyle: {
                            normal: {
                                color: '#73DABE',
                            },
                        },
                    };
                    // 预计销售方量合计
                    const flTotalData = res.date.map(item => {
                        const arr = res.list.filter(key => {
                            return key.date === item;
                        });
                        let total = 0;
                        arr.forEach(a => {
                            total = Math.round(total * 100 + a.yjxsfl * 100) / 100;
                        });
                        return total;
                    });
                    const flTotal = {
                        name: '合计',
                        type: 'line',
                        yAxisIndex: 1,
                        data: flTotalData,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            fontSize: '14',
                            padding: 5,
                        },
                        itemStyle: {
                            normal: {
                                color: '#73DABE',
                            },
                        },
                    };
                    const htCompleteData = htEchartSeriesData.slice(0, -1);
                    htCompleteData.push(htTotal);
                    const flCompleteData = flEchartSeriesData.slice(0, -1);
                    flCompleteData.push(flTotal);
                    setTimeout(() => {
                        // 合同数量图表
                        this.contractQuantityCh(htCompleteData);
                        // 预计销售方量图表
                        this.estimatedSalesCh(flCompleteData);
                    }, 200);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};

</script>
<style lang='stylus'>

</style>